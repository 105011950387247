import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppContent from './AppContent';
import axios from 'axios';
import Loader from './components/Loader';
import Header from './components/Header';
import Footer from './components/Footer';
import BASE_URL from './config/config';

const Routering = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const verificarToken = async () => {
      try {
        const token = localStorage.getItem('AuthToken');
        if (!token) {
          throw new Error('No se encontró token');
        }

        const response = await axios.get(`${BASE_URL}/autenticacion`, {
          headers: {
            Authorization: `${token}`,
          },
        });

        setAuthenticated(true);
        setUserRole(response.data.role);
      } catch (error) {
        setAuthenticated(false);
        setUserRole(null);
        localStorage.removeItem('AuthToken');
      } finally {
        setLoading(false);
      }
    };

    verificarToken();
  }, []);

  return (
    <Router>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header authenticated={authenticated} />
          <AppContent authenticated={authenticated} userRole={userRole} />
          <Footer />
        </>
      )}
    </Router>
  );
};

export default Routering;
