import React from 'react';
import ReactDOM from 'react-dom';

import './css/style.css';
import Routering from './Routering';
import Header from './components/Header';
import Footer from './components/Footer';

ReactDOM.render( <
    React.StrictMode >
    <
    Routering > < /Routering> <
    /React.StrictMode>,
    document.getElementById('root')
);