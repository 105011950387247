import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Inicio from './pages/Inicio';
import IniciarSesion from './pages/IniciarSesion';
import Dashboard from './pages/usuario/Dashboard';
import NuevoTicket from './pages/usuario/NuevoTicket';
import TicketCreado from './pages/usuario/TicketCreado';
import Ticket from './pages/usuario/Ticket';
import Servicios from './pages/usuario/Servicios';
import TicketManager from './pages/usuario/admin/TicketManager';
import UserManager from './pages/usuario/admin/UserManager';
import EmpiezaAhora from './pages/usuario/EmpiezaAhora';
import RecuperarContrasena from './pages/RecuperarContrasena';
import EditarContrasena from './pages/EditarContrasena';
import TicketArchivados from './pages/usuario/admin/TicketArchivados';
import Versiones from './pages/Versiones';
import Error404 from './components/Error404';
import PageLoader from './PageLoader';

const AppContent = ({ authenticated, userRole }) => {
  const canAccess = (allowedRoles) => allowedRoles.includes(userRole);

  return (
    <PageLoader>
      <Routes>
        <Route path="/" element={<Inicio />} />
        <Route path="/registrarse" element={<EmpiezaAhora />} />
        <Route path="/recuperar-contrasena" element={<RecuperarContrasena />} />
        <Route path="/editar-contrasena" element={<EditarContrasena />} />
        <Route
          path="/iniciar-sesion"
          element={authenticated ? <Navigate to="/dashboard" /> : <IniciarSesion />}
        />
        <Route
          path="/dashboard"
          element={authenticated ? <Dashboard /> : <Navigate to="/iniciar-sesion" />}
        />
        <Route
          path="/dashboard/servicios"
          element={authenticated ? <Servicios /> : <Navigate to="/iniciar-sesion" />}
        />
        <Route
          path="/dashboard/tickets-manager"
          element={authenticated && canAccess(['admin']) ? <TicketManager /> : <Navigate to="/dashboard" />}
        />
        <Route
          path="/dashboard/tickets-archivados"
          element={authenticated && canAccess(['admin']) ? <TicketArchivados /> : <Navigate to="/dashboard" />}
        />
        <Route
          path="/dashboard/users-manager"
          element={authenticated && canAccess(['admin']) ? <UserManager /> : <Navigate to={authenticated ? '/dashboard' : '/iniciar-sesion'} />}
        />
        <Route
          path="/nuevo-ticket"
          element={authenticated ? <NuevoTicket /> : <Navigate to="/iniciar-sesion" />}
        />
        <Route
          path="/ticket-creado/:identificador"
          element={authenticated ? <TicketCreado /> : <Navigate to="/iniciar-sesion" />}
        />
        <Route
          path="/ticket/:identificador"
          element={authenticated ? <Ticket /> : <Navigate to="/iniciar-sesion" />}
        />
        <Route
          path="/version"
          element={authenticated ? <Versiones /> : <Navigate to="/iniciar-sesion" />}
        />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </PageLoader>
  );
};

export default AppContent;
