import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Loader from './components/Loader';
import Header from './components/Header';

const PageLoader = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setLoading(true); // Mostrar el loader inmediatamente al cambiar de ruta

    const timeout = setTimeout(() => {
      setLoading(false); // Ocultar el loader después de 300ms
    }, 500);

    return () => clearTimeout(timeout); // Limpia el timeout al desmontar
  }, [location]);

  return loading ? 
  <>
    <Loader /> 
  </>

  : children; // Renderiza el loader o el contenido
};

export default PageLoader;
