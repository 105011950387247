import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import Snowfall from 'react-snowfall';
import Notificaciones from './Notificaciones';

const Header = ({ authenticated }) => {
    const token = localStorage.getItem("AuthToken");
   

    let tokenInfo = {};
    if (token) {
        tokenInfo = jwtDecode(token);
    }

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleLinkClick = () => {
        window.scrollTo({ top: 0 });

        const checkbox = document.getElementById("menu-toggle");
        if (checkbox) {
            checkbox.checked = false; // Desmarca el checkbox
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("AuthToken");
        window.location.reload();
    };



    return (
        <header className={isScrolled ? 'scrolled' : ''}>
            <div className='site-width grid-header'>
                <Link to="/" className='logo-link' onClick={handleLinkClick}>
                    <img className='header-logo' src='/images/logoadhoc-negro.svg' alt='Logo ADHOC'/>
                    {/* <img className='icono-navideno' src='/images/gorro-navideno.svg' alt='Icono gorrito'/> */}
                    <div>
                        <p className='p-soporte'>Soporte</p>
                        {/* <Snowfall snowflakeCount={2} />*/}
                    </div>
                </Link>
                <div className="user-actions">
                    {authenticated ? (
                        <>
                            {tokenInfo.estado === "verificar" ? '' :
                                <>
                                    <Notificaciones />
                                    <Link to="/nuevo-ticket" className='crear-ticket' onClick={handleLinkClick}>
                                        Crear ticket
                                    </Link>
                                </>
                            }
                            <Link to="/dashboard" className='dashboard' onClick={handleLinkClick}>
                                Dashboard
                            </Link>
                        </>
                    ) : (
                        <>
                            <Link to="/iniciar-sesion" className='login' onClick={handleLinkClick}>
                                Iniciar sesión
                            </Link>
                            <Link to="/registrarse" className='new-user' onClick={handleLinkClick}>
                                Empieza ahora
                            </Link>
                        </>
                    )}
                </div>
                <div className="grid-burger">
                {authenticated ? (<Notificaciones />) : ''}
                <div className="adhoc-burger">
                    <input type="checkbox" id="menu-toggle" />
                    <label htmlFor="menu-toggle" className="menu-icon">
                        <div className="line top"></div>
                        <div className="line bottom"></div>
                    </label>
                    <div className="menu-responsive">
                        <Link to="/" className='logo-link' onClick={handleLinkClick}>
                            <img className='lateral-logo' src='/images/logoadhoc-negro.svg' alt='Logo ADHOC'/>
                        </Link>
                        <div className="user-actions">
                            {authenticated ? (
                                <>
                                    {tokenInfo.estado === "verificar" ? '' :
                                        <>
                                            <Link to="/nuevo-ticket" className='crear-ticket' onClick={handleLinkClick}>
                                                Crear ticket
                                            </Link>
                                        </>
                                    }
                                    <Link to="/dashboard" className='dashboard' onClick={handleLinkClick}>
                                        Dashboard
                                    </Link>
                                    <button className='logout' onClick={handleLogout}>
                                        <img src="/images/cerrar-sesion.svg" alt="Logout" />
                                        <p>Salir</p>
                                    </button>
                                </>
                            ) : (
                                <>
                                    <Link to="/iniciar-sesion" className='login' onClick={handleLinkClick}>
                                        Iniciar sesión
                                    </Link>
                                    <Link to="/registrarse" className='new-user' onClick={handleLinkClick}>
                                        Empieza ahora
                                    </Link>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                </div>
            
            </div>
        </header>
    );
};

export default Header;
